import React from "react";
import {Nav} from "../../components";
import Footer from "../../components/footer";

const ThankYou = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Thank you for using Swaptecs'}
            darkNav={true}
        />

        <div className="pageContainer" style={{paddingTop: '10rem'}}>

            <h1>Thank you!</h1>

        </div>

        <Footer />
    </div>
}

export default ThankYou;